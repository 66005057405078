import { InputCalendar, InputText } from '~/components/BaseForm/core/primitives'
import {
  Currency,
  Firstname,
  Lastname,
  Email,
  Phone,
} from '~/components/BaseForm/core/fields'
import type { CoreGroupFields } from '~/components/BaseForm/types'

export default (): CoreGroupFields => {
  const firstname: typeof Firstname = {
    ...Firstname,
    attr: {
      ...Firstname.attr,
      name: 'firstname',
      labelTranslateKey: 'field.firstName',
    },
    containerInputClass: 'w-1/2 px-2',
  }

  const lastname: typeof Lastname = {
    ...Lastname,
    attr: {
      ...Lastname.attr,
      name: 'lastname',
      labelTranslateKey: 'field.lastName',
    },
    containerInputClass: 'w-1/2 px-2',
  }

  const email: typeof Email = {
    ...Email,
    attr: {
      ...Email.attr,
      labelTranslateKey: 'field.email',
    },
    containerInputClass: 'w-full md:w-1/2 px-2',
  }

  const phone: typeof Phone = {
    ...Phone,
    attr: {
      ...Phone.attr,
      labelTranslateKey: 'field.phone',
    },
    containerInputClass: 'w-full md:w-1/2 px-2',
  }

  const calendar: typeof InputCalendar = {
    ...InputCalendar,
    attr: {
      ...InputCalendar.attr,
      disabledDaysBeforeDayDate: false,
      labelTranslateKey: 'global.travel_dates',
      name: 'calendar',
      rules: 'calendarRequired',
    },
    containerInputClass: 'w-full md:w-12/24 px-2',
  }
  const budget: typeof InputText = {
    ...InputText,
    attr: {
      ...InputText.attr,
      labelTranslateKey: 'field.budget',
      rules: 'required',
      name: 'budget',
      inputmode: 'numeric',
      type: 'number',
      placeholderTranslateKey: 'destination.form.budget_placeholder',
    },
    containerInputClass: 'md:w-8/24 px-2',
  }
  const currency: typeof Currency = {
    ...Currency,
    attr: {
      ...Currency.attr,
      labelTextTranslateKey: '',
      name: 'currency',
      rules: 'required',
    },
    containerInputClass: 'w-9/24 md:w-4/24 mt-[25px] px-2',
  }
  const guests: typeof InputText = {
    ...InputText,
    attr: {
      ...InputText.attr,
      inputmode: 'numeric',
      type: 'number',
      labelTranslateKey: 'field.guests',
      rules: 'required',
      name: 'guests',
    },
    containerInputClass: 'w-full md:w-1/2 px-2',
  }

  return {
    containerGroupFieldsClass: 'flex flex-wrap -mx-2',
    fields: [
      firstname,
      lastname,
      email,
      phone,
      calendar,
      budget,
      currency,
      guests,
    ],
  }
}
