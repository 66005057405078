<script setup lang="ts">
import { useButtons } from '~/components/BaseForm/compose/use-buttons'
import groupFields from '~/forms/newsletter/block/form/groupFields'

const { $api } = useNuxtApp()
const { t } = useI18n()
const { trackEvent } = useTracking()

const groupFieldsForm = reactive(groupFields())
const { btnPrimary } = useButtons()
btnPrimary.text = computed(() => t('popinNewsletter.iSubscribe'))
if (btnPrimary.attrs) {
  btnPrimary.attrs.class = 'mt-4 md:mt-0 base-form-button'
  btnPrimary.attrs.blockFull = true
}

const initialData = reactive<{ email?: string }>({})
const success = ref(false)

const submitNewsletter = (formData: { email: string }) =>
  $api.v1.newsletterEmails.create({ email: formData.email })
const mutation = (data: { email: string }) => ({
  request: submitNewsletter,
  input: {
    email: data.email,
  },
})

const onSubmit = () => ({
  success: () => {
    initialData.email = ''
    success.value = true
    trackEvent({
      event: 'newsletter_subscription',
      label: 'block_lp',
      post_alert: false,
    })
  },
  error: () => {
    success.value = false
  },
})
</script>

<template>
  <div class="bg-primary-100 py-16 px-4">
    <div class="lg:max-w-[960px] lg:mx-auto">
      <div class="lg:flex lg:items-center">
        <BaseNuxtImg
          src="production/assets/images/newsletter/pop_in_desktop.jpg"
          alt="img"
          class="mb-11 lg:mb-0 lg:mr-6"
          width="490"
          height="320"
        />
        <div class="lg:ml-6">
          <div class="font-monospace text-4xl font-bold mb-4 leading-8">
            {{ $t('popinNewsletter.title') }}
          </div>

          <p class="mb-9">
            {{ $t('popinNewsletter.text') }}
          </p>

          <base-form
            v-if="!success"
            ref="formDestinationNewsletter"
            :class="{ flex: !$device.isMobile }"
            :container-button-class="!$device.isMobile ? 'w-60 flex ml-4' : ''"
            :active-modal-errors="false"
            :active-modal-save-or-quit="false"
            :button-primary="btnPrimary"
            :group-fields="groupFieldsForm"
            :initial-data="initialData"
            :mutation="mutation"
            :on-submit="onSubmit"
            id-form="destination-newsletter-form"
            container-form-class="w-full"
          />
          <div v-else class="font-semibold">
            {{ $t('popinNewsletter.thankYou') }},
            <span class="lowercase">{{ $t('popinNewsletter.text2') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#destination-newsletter-form .base-form--error {
  @apply absolute;
}
#destination-newsletter-form .base-form-button {
  font-size: 14px;
}
</style>
