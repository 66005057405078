<script setup lang="ts">
import type { Locale } from 'lc-services/types'
import type { StateDestination } from '~/types/types'

const { locale, t } = useI18n()
const stateDestination = useState<StateDestination>('destination')
const { isSmallScreen } = useBreakpoint()
const { userIsAdmin, userIsPartner } = useAuth()

const { collections, destination, destinations, lpContent, linksSeo } =
  stateDestination.value

const firstInputFocus = ref(false)
const showFeaturedHouses = computed(() => {
  let isItComplete = true

  if (collections.length) {
    isItComplete =
      collections.reduce((acc, cur) => acc + cur.houses.length, 0) === 12
  } else {
    isItComplete = false
  }

  return isItComplete
})
const showSeoBlock = computed(
  () =>
    !!lpContent.title[locale.value as Locale] &&
    !!lpContent.description[locale.value as Locale],
)
const propertyType = computed(() => {
  let destiType = null

  if (lpContent.searchUrl?.fr || lpContent.searchUrl?.en) {
    destiType = lpContent.searchUrl[locale.value as Locale]
      .split('/')[0]
      .split('-')[1]
  } else {
    destiType =
      locale.value === 'fr'
        ? `location-villas/${lpContent.slugFr}`
        : `luxury-villas-rentals/${lpContent.slugEn}`

    return destiType.split('/')[0].split('-')[1]
  }

  return destiType
})
const scrollTo = computed(() =>
  showFeaturedHouses.value ? '#collections' : '#destiForm',
)

const searchLinksTitle = computed(() => {
  const destinationType = destination.searchUrl?.[locale.value as Locale]
    ?.split('/')?.[0]
    ?.split('-')?.[1]

  return t('search.typesOfVillas', {
    destination: destination.name,
    houseType: destinationType,
  })
})

const inputFocus = () => {
  useScrollTo({ id: 'destiForm' })

  setTimeout(() => {
    firstInputFocus.value = true
  }, 500)
}
</script>

<template>
  <div class="desti__container">
    <general-heading-block
      :picture="lpContent.coverPhotoUrl"
      :label="lpContent.coverLabel[locale as Locale]"
      :title="lpContent.coverTitle[locale as Locale]"
      :subtitle="lpContent.coverSubtitle[locale as Locale]"
      :alt-image="lpContent.coverPhotoAlt[locale as Locale]"
      :arrow-scroll-to="!$device.isMobile ? scrollTo : null"
      class="mx-0 mb-0 mt-header"
    >
      <base-button
        v-if="showFeaturedHouses"
        color="secondary"
        block
        :block-full="$device.isMobile"
        class="mx-auto my-1 desti__section__button"
        @click="useScrollTo({ id: 'collections' })"
      >
        {{ $t('destination.discover_selection') }}
      </base-button>
      <base-button variant="link" color="white" @click="inputFocus">
        {{ $t('destination.contact_tailor') }}
      </base-button>
    </general-heading-block>

    <general-featured-houses-block
      v-if="showFeaturedHouses"
      id="collections"
      :collections="collections"
      :desti-id="
        lpContent.destinationId ? Number(lpContent.destinationId) : null
      "
      :houses-count="destination.housesCount"
      :property-type="propertyType"
      :search-button-visible="lpContent.searchButtonOnDestinationPage"
      :title="lpContent.seoSectionTitle[locale as Locale]"
      page="Destination landing"
      class="desti__section--collection"
    />

    <base-newsletter-block v-if="lpContent.isLpInspiration" />

    <base-section-content
      id="destiForm"
      :title="$t('destination.form.title')"
      :subtitle="$t('destination.form.description')"
      class="desti__section--form"
    >
      <template #content>
        <destination-form
          :desti-id="
            lpContent.destinationId ? Number(lpContent.destinationId) : null
          "
          :is-lp-inspiration="lpContent.isLpInspiration"
          :lp-inspiration-name="lpContent.seoSectionTitle.en"
          :lp-inspiration-slug="lpContent.slugEn"
        />
      </template>
    </base-section-content>

    <base-section-content
      :title="$t('destination.services.title')"
      :subtitle="$t('global.services.description')"
      class="desti__section"
    >
      <template #header>
        <div
          :data-aos-once="true"
          data-aos="fade"
          data-aos-offset="0"
          data-aos-delay="300"
        >
          <base-sliding-input
            :desti-id="
              lpContent.destinationId ? Number(lpContent.destinationId) : null
            "
            :inline="!isSmallScreen"
            class="desti__sliding-input"
            section="freedom"
          />
        </div>
      </template>
      <template #content>
        <general-services-block />
      </template>
    </base-section-content>

    <destination-about-block
      v-if="showSeoBlock"
      :title="lpContent.title[locale as Locale]"
      :description="lpContent.description[locale as Locale]"
      :seo-photo-url="lpContent.seoPhotoUrl"
      :seo-featured-photo-description="
        lpContent.seoFeaturedPhotoDescription[locale as Locale]
      "
      :how-to-get-there="lpContent.howToGetThere[locale as Locale]"
      :when-to-leave-text="lpContent.whenToLeaveText[locale as Locale]"
      class="desti__section--about"
    />

    <base-section-content :title="$t('global.destinations.title')">
      <template #content>
        <general-destinations-block-collapse
          :destinations="destinations"
          :desti-id="
            lpContent.destinationId ? String(lpContent.destinationId) : null
          "
          page="Destination landing"
        />
      </template>
    </base-section-content>

    <SearchLinks
      v-if="linksSeo?.length && !userIsAdmin && !userIsPartner"
      :links-seo="linksSeo"
      :title="searchLinksTitle"
      class="mt-10"
    />

    <general-medias-block class="mt-32" />

    <general-values-block />
  </div>
</template>

<style scoped>
.desti__container {
  @apply w-full;
  scroll-behavior: smooth;
}

.desti__section,
.desti__section--form {
  margin: 0 0 5rem 0;
}

@screen md {
  .desti__section,
  .desti__section--form {
    margin: 0 0 7.5rem 0;
  }
}

@screen lg {
  .desti__section,
  .desti__section--form {
    margin: 0 0 10rem 0;
  }
}

.desti__section__button {
  max-width: 300px;
}

.desti__sliding-input {
  @apply mb-14;
}

.desti__section--collection {
  @apply my-20 mx-0;
}

@screen md {
  .desti__section--collection {
    margin: 7.5rem 0 5rem 0;
  }
}

.desti__section--about {
  @apply mb-20;
}

@screen md {
  .desti__section--about {
    margin-bottom: 7.5rem;
  }
}

@screen lg {
  .desti__section--about {
    @apply mb-40;
  }
}

.desti__section--form {
  @apply bg-gray-100 py-20 px-0;
  margin: 0 0 5rem 0;
}
</style>
