import type { Booking, BookingColor, Period } from 'vue-calendar-3'
import type {
  CalendarPlaceholder,
  ConditionsField,
  Rules,
} from '~/components/BaseForm/types'

type InputCalendar = {
  conditions?: ConditionsField
  containerInputClass?: string
  hidden?: boolean
  inputType: 'calendar'
  order?: number
  viewModeRow?: boolean
  attr: {
    alwaysVisible?: boolean
    bookedDates?: string[]
    bookingColor: BookingColor
    bookingDates?: Booking[]
    disabled?: boolean
    disabledDaysAfterDayDate?: boolean
    disabledDaysBeforeDayDate: boolean
    formatDate?: string
    inputHtml?: string
    labelHtml?: string
    labelTranslateKey: string
    name: string
    periodDates?: Period[]
    placeholder?: CalendarPlaceholder
    placeholderCalendarTranslatedKeys: CalendarPlaceholder
    position?: 'left' | 'right'
    rules?: Rules
    showInputCalendar?: boolean
    showYear?: boolean
    wrapperClass?: string
  }
}

const inputCalendar: InputCalendar = {
  inputType: 'calendar',
  attr: {
    bookingColor: {
      blocked: '#cacaca',
    },
    disabledDaysBeforeDayDate: true,
    labelTranslateKey: '',
    name: 'calendar',
    placeholderCalendarTranslatedKeys: {
      startDate: 'global.placeholders.calendarCheckIn',
      endDate: 'global.placeholders.calendarCheckOut',
    },
  },
}

export default inputCalendar
